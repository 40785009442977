
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "approve-confirm",
  components: {},
  data() {
    return {
      title: ref<string>(""),
      message: ref<string>(""),
    };
  },
  methods: {
    async approveRequest() {
      try {
        const route = useRoute();
        const sortId = route.params.shortId;
        const { status } = await ApiService.vueInstance.axios.post(
          `request/v1/approve/${sortId}`
        );
        if (status == 200) {
          this.title = "Request Approved";
          this.message = "You have successfully approve the reqeust";
        } else {
          this.title = "Something happened";
          this.message =
            "Something happened when try to approveing the request";
        }
      } catch (error) {
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error.message);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },
  },
  mounted() {
    this.approveRequest();
  },
});
