import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto" }
const _hoisted_2 = { className: "message mb-4 mt-0" }
const _hoisted_3 = { className: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.message), 1)
  ]))
}